import { auth } from "@/firebase";
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/profile/Profile.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/profile/edit",
    name: "Profile edit",
    component: () => import("../views/profile/ProfileEdit.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/profile/mentor",
    name: "Profile mentor",
    component: () => import("../views/profile/ProfileMentor.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("../views/Categories.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/mentors",
    name: "Mentors",
    component: () => import("../views/Mentors.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: () => import("../views/Sessions.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/sign-in",
    name: "Sign-in",
    component: () => import("../views/SignIn.vue"),
    meta: {
      emptyHeader: true,
    },
  },
  {
    path: "/verify_email/:token",
    name: "Verify email",
    component: () => import("../views/auth/Verify.vue"),
  },
  {
    path: "/verify_password/:token",
    name: "Verify password",
    component: () => import("../views/auth/Verify.vue"),
    meta: {
      emptyHeader: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    };
  },
});

router.beforeResolve(async (to, from, next) => {
  const currentUser = auth.currentUser;
  const idTokenResult = await currentUser?.getIdTokenResult();
  const isAdmin = idTokenResult?.claims.admin;
  // signout non-admin
  if (currentUser && !isAdmin) {
    store.dispatch("authentication/signOut");
    return next("/sign-in");
  }
  // redirect by logged in status
  if (to.meta.authRequired && !currentUser) return next("/sign-in");
  if (to.path == "/sign-in" && currentUser && isAdmin) return next("/");

  return next();
});

export default router;
