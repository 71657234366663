<template>
  <nav
    class="w-full flex items-center justify-between flex-wrap border-b-[1px] border-darkblue-300 gap-2 px-6 h-16 md:h-[76px]"
  >
    <div class="flex items-center md:flex-shrink-0 md:mr-6">
      <img
        class="h-8 object-contain cursor-pointer"
        src="/assets/logo.svg"
        @click="router.push('/')"
      />
    </div>
    <div v-if="!hideMenu" class="flex flex-grow items-center w-auto h-full">
      <navigation-items
        @nav-clicked="toggleNavigation"
        class="hidden md:flex"
      />
      <navigation-profile class="ml-auto" />
    </div>
  </nav>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import NavigationItems from "./NavigationItems.vue";
import NavigationProfile from "./NavigationProfile.vue";
export default {
  components: {
    NavigationItems,
    NavigationProfile,
  },
  props: {
    hideMenu: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();

    const showNavigation = ref(false);
    const toggleNavigation = () =>
      (showNavigation.value = !showNavigation.value);

    return {
      showNavigation,
      t,
      router,
      toggleNavigation,
    };
  },
};
</script>
