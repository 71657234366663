import { createStore } from "vuex";
import authentication from "./modules/authentication.store";
import category from "./modules/category.store";
import user from "./modules/user.store";
import session from "./modules/session.store";

export default createStore({
  state: {},
  mutations: {},
  actions: {
    clearAll({ commit }) {
      commit("authentication/resetState");
      commit("category/resetState");
      commit("user/resetState");
      commit("session/resetState");
    },
  },
  modules: {
    authentication,
    category,
    user,
    session,
  },
});
