<template>
  <div class="relative flex cursor-pointer">
    <div
      class="focus:outline-none flex cursor-pointer items-center focus:ring focus:ring-inset focus:ring-darkblue-600"
      @click="showSelect = !showSelect"
    >
      <div class="flex items-center gap-2">
        <span class="hidden md:block capitalize">
          {{ user?.firstName }}
        </span>
        <img
          v-if="user.avatar"
          class="aspect-square h-[46px] w-[46px] rounded-full object-cover"
          style="object-position: 50% 20%"
          :src="getAvatar(user)"
        />
        <div
          v-else
          class="aspect-square flex h-[46px] w-[46px] items-center justify-center rounded-full"
        >
          <User class="h-6 w-6 text-white" />
        </div>
        <div class="hidden md:block">
          <ArrowRight2
            class="h-6 w-6 text-white transition-transform"
            :class="{ 'rotate-90': showSelect }"
          />
        </div>
      </div>
    </div>
    <div
      v-if="showSelect"
      id="nav-profile-menu"
      class="h-30 absolute right-0 bottom-0 flex w-60 translate-y-full transform flex-col gap-y-1 shadow-md rounded-lg bg-darkblue border-2 border-darkblue-400 z-50"
    >
      <div
        v-for="(item, index) in items"
        :key="item.label"
        class="focus:outline-none flex cursor-pointer items-center rounded-md py-2 px-3 focus:ring hover:bg-darkblue-400"
        @click="
          item.command();
          showSelect = false;
        "
        @mouseover="hovers[index] = true"
        @mouseleave="hovers[index] = false"
      >
        <component class="h-6 w-6" :class="item.class" :is="item.icon" />
        <span class="ml-3 font-semibold">{{
          t(`navigation.${item.label}`)
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, markRaw, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ArrowRight2, LogoutCurve, User } from "vue-iconsax";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getAvatar } from "../../utils/imageUtils";

export default {
  components: { User, ArrowRight2 },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const showSelect = ref(false),
      hovers = ref([]),
      items = reactive([
        {
          command: () => router.push("/profile"),
          icon: markRaw(User),
          label: "profile",
        },
        {
          command: () => store.dispatch("authentication/signOut"),
          icon: markRaw(LogoutCurve),
          label: "sign_out",
        },
      ]);

    // close menu on away click
    window.addEventListener("pointerup", (event) => {
      if (!event.target.closest("#nav-profile-menu")) showSelect.value = false;
    });

    return {
      getAvatar: (user) => getAvatar(user),
      router,
      t,
      user: computed(() => store.state.authentication.user),
      showSelect,
      hovers,
      items,
    };
  },
};
</script>
