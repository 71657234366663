import { db, functions } from "@/firebase";
import {
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    allMentors: [],
    categoryMentors: [],
    contacts: [],
    mentor: {},
    mentorSearchResults: [],
    personnel: [],
    popularCategoryMentors: [],
    status: {},
    upcomingSessions: [],
    user: {},
    users: [],
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async createUser({ commit }, data) {
    try {
      commit("wait");
      const createUser = httpsCallable(functions, "createUser");
      const res = await createUser(data);

      if (!res.data.success) throw "unknown";
      commit("addUser", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  /*async createAdmin({ commit }, data) {
    try {
      commit("wait");
      const createAdmin = httpsCallable(functions, "createAdmin");
      const res = await createAdmin(data);

      if (!res.data.success) throw "unknown";
      commit("addUser", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },*/
  async getUsers({ commit }) {
    try {
      commit("wait");
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);
      const users = querySnapshot.docs.map((doc) => {
        const user = doc.data();
        user.id = doc.id;
        return user;
      });
      commit("setUsers", users);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getUser({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const user = docSnap.data();
        user.id = docSnap.id;
        commit("setUser", user);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getMentorUser({ commit }, id) {
    try {
      commit("wait");
      const getMentor = httpsCallable(functions, "getMentorUnsanitized");
      const res = await getMentor(id);

      if (!res.data.success) throw "unknown";
      commit("setMentor", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async inviteMentorUser({ commit }, data) {
    try {
      commit("wait");
      const inviteMentor = httpsCallable(functions, "inviteMentor");
      const res = await inviteMentor(data);

      if (!res.data.success) throw "unknown";

      data.id = res.data.mentorId;
      data.status = "invited";
      state.allMentors.push(data);

      commit("setSuccess", "invitation_sent");
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateMentorUser({ commit }, data) {
    try {
      commit("wait");
      const updateMentor = httpsCallable(functions, "updateMentor");
      await updateMentor(data);

      const index = state.allMentors.findIndex(
        (mentor) => mentor.id === data.mentorId
      );
      let keys = Object.keys(data);
      if (index !== -1) {
        for (let key of keys) {
          if (state.allMentors[index][key]) {
            state.allMentors[index][key] = data[key];
          }
        }
      }
      commit("setSuccess", "mentor_updated");
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateMentorUserLoginInfo({ commit }, data) {
    try {
      commit("wait");
      const updateMentorLoginInfo = httpsCallable(
        functions,
        "updateMentorLoginInfo"
      );
      const res = await updateMentorLoginInfo(data);

      if (!res.data.success) throw res.data.code;

      const match = state.allMentors.find(
        (mentor) => mentor.id === data.mentorId
      );

      // If a matching mentor is found, update the email value
      if (match && match.email != data.email) {
        match.email = data.email;
      }
      commit("setSuccess", "login_info_updated");
    } catch (error) {
      commit("failure", error);
    }
  },
  async deleteMentorUser({ commit }, mentorId) {
    try {
      commit("wait");
      const deleteMentor = httpsCallable(functions, "deleteMentor");
      await deleteMentor(mentorId);

      const newAllMentors = state.allMentors.filter(
        (mentor) => mentor.id !== mentorId
      );
      commit("setAllMentors", newAllMentors);
      commit("setSuccess", "mentor_deleted");
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateMentorService({ commit }, data) {
    try {
      commit("wait");
      const adminUpdateMentorService = httpsCallable(
        functions,
        "adminUpdateMentorService"
      );
      const res = await adminUpdateMentorService(data);
      if (!res.data.success) throw res.data.code || "unknown";

      const index = state.allMentors.findIndex(
        (mentor) => mentor.id === data.user
      );
      let keys = Object.keys(data);
      if (index !== -1) {
        for (let key of keys) {
          if (state.allMentors[index][key]) {
            state.allMentors[index][key] = data[key];
          }
        }
      }
      commit("setSuccess", "mentor_updated");
      return { success: true };
    } catch (error) {
      commit("failure", error);
      return { success: false };
    }
  },
  async deleteMentorService({ commit }, data) {
    try {
      commit("wait");
      const adminDeleteMentorService = httpsCallable(
        functions,
        "adminDeleteMentorService"
      );
      const res = await adminDeleteMentorService(data);
      if (!res.data.success) throw res.data.code || "unknown";

      const index = state.allMentors.findIndex(
        (mentor) => mentor.id === data.user
      );
      delete state.allMentors[index][data.category];
      const catIndex = state.allMentors[index].categories.findIndex(
        (categoryId) => categoryId === data.category
      );
      state.allMentors[index].categories.splice(catIndex, 1);
      commit("setSuccess", "mentor_updated");
      return { success: true };
    } catch (error) {
      commit("failure", error);
      return { success: false };
    }
  },
  async getAllMentors({ commit }) {
    try {
      commit("wait");
      const q = query(collection(db, "users"), where("role", "==", "mentor"));
      const querySnapshot = await getDocs(q);
      const mentors = querySnapshot.docs.map((doc) => {
        const mentor = doc.data();
        mentor.id = doc.id;
        if (!doc.data().firstName) mentor.firstName = "";
        if (!doc.data().lastName) mentor.lastName = "";
        return mentor;
      });
      commit("setAllMentors", mentors);
    } catch (error) {
      commit("failure", error);
    }
  },
  /*async getPopularMentors({ commit }) {
    try {
      commit("wait");
      const q = query(
        collection(db, "users"),
        where("role", "==", "mentor"),
        orderBy("sessionsSold", "desc"),
        limit(8)
      );
      const querySnapshot = await getDocs(q);
      const popularMentors = querySnapshot.docs.map((doc) => {
        const popularMentor = doc.data();
        popularMentor.id = doc.id;
        return popularMentor;
      });
      commit("setPopularMentors", popularMentors);
    } catch (error) {
      commit("failure", error);
    }
  },*/
  async getPopularCategoryMentors({ commit }, categoryId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "users"),
        where("role", "==", "mentor"),
        where("categoryId", "==", categoryId),
        orderBy("sessionsSold", "desc"),
        limit(5)
      );
      const querySnapshot = await getDocs(q);
      const popularMentors = querySnapshot.docs.map((doc) => {
        const popularMentor = doc.data();
        popularMentor.id = doc.id;
        return popularMentor;
      });
      commit("setPopularCategoryMentors", popularMentors);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getCategoryMentors({ commit }, categoryId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "users"),
        where("role", "==", "mentor"),
        where("categoryId", "==", categoryId),
        orderBy("sessionsSold", "desc"),
        limit(5)
      );
      const querySnapshot = await getDocs(q);
      const categoryMentors = querySnapshot.docs.map((doc) => {
        const categoryMentor = doc.data();
        categoryMentor.id = doc.id;
        return categoryMentor;
      });
      commit("setCategoryMentors", categoryMentors);
    } catch (error) {
      commit("failure", error);
    }
  } /*
  async getLatestMentors({ commit }) {
    try {
      commit("wait");
      const q = query(
        collection(db, "users"),
        where("role", "==", "mentor"),
        orderBy("createdAt", "desc"),
        limit(5)
      );
      const querySnapshot = await getDocs(q);
      const latestMentors = querySnapshot.docs.map((doc) => {
        const latestMentor = doc.data();
        latestMentor.id = doc.id;
        return latestMentor;
      });
      commit("setLatestMentors", latestMentors);
    } catch (error) {
      commit("failure", error);
    }
  },*/,
  async getUpcomingSessions({ commit }, userId) {
    const today = new Date().toISOString().split("T", 1)[0];
    try {
      commit("wait");
      const q = query(
        collection(db, "sessions"),
        where("date", ">=", today),
        where("customerId", "==", userId),
        orderBy("date", "asc"),
        limit(5)
      );
      const querySnapshot = await getDocs(q);
      const upcomingSessions = querySnapshot.docs.map((doc) => {
        const upcomingSession = doc.data();
        upcomingSession.id = doc.id;
        return upcomingSession;
      });
      commit("setUpcomingSessions", upcomingSessions);
    } catch (error) {
      commit("failure", error);
    }
  },
  async searchMentors({ commit }, { searchInput, filters }) {
    try {
      commit("wait");
      let q = "";
      if (!filters.length) {
        q = query(
          collection(db, "users"),
          and(
            where("role", "==", "mentor"),
            or(
              where("firstName", "==", searchInput),
              where("lastName", "==", searchInput),
              where("tags", "array-contains", searchInput)
            )
          )
        );
      } else {
        q = query(
          collection(db, "users"),
          and(
            where("role", "==", "mentor"),
            where("categoryId", "in", filters),
            or(
              where("firstName", "==", searchInput),
              where("lastName", "==", searchInput),
              where("tags", "array-contains", searchInput)
            )
          )
        );
      }
      const querySnapshot = await getDocs(q);
      const mentorMatches = querySnapshot.docs.map((doc) => {
        const match = doc.data();
        match.id = doc.id;
        return match;
      });
      commit("setMentorSearchResults", mentorMatches);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addEmployee(state, employee) {
    state.personnel.push(employee);
    state.waiting = false;
  },
  addUser(state, user) {
    state.users.push(user);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setMentor(state, mentor) {
    state.mentor = mentor;
    state.waiting = false;
  },
  setAllMentors(state, mentors) {
    state.allMentors = mentors;
    state.waiting = false;
  },
  setContacts(state, contacts) {
    state.contacts = contacts;
    state.waiting = false;
  },
  setPersonnel(state, personnel) {
    state.personnel = personnel;
    state.waiting = false;
  },
  setPopularMentors(state, popularMentors) {
    state.popularMentors = popularMentors;
    state.waiting = false;
  },
  setPopularCategoryMentors(state, popularMentors) {
    state.popularCategoryMentors = popularMentors;
    state.waiting = false;
  },
  setCategoryMentors(state, categoryMentors) {
    state.categoryMentors = categoryMentors;
    state.waiting = false;
  },
  setLatestMentors(state, latestMentors) {
    state.latestMentors = latestMentors;
    state.waiting = false;
  },
  setMentorSearchResults(state, matches) {
    state.mentorSearchResults = matches;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  setUpcomingSessions(state, upcomingSessions) {
    state.upcomingSessions = upcomingSessions;
    state.waiting = false;
  },
  setUser(state, user) {
    state.user = user;
    state.waiting = false;
  },
  setUsers(state, users) {
    state.users = users;
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getUser(state) {
    return state.user;
  },
  getMentor(state) {
    return state.mentor;
  },
  getMentors(state) {
    return state.mentors;
  },
  getPopularMentors(state) {
    const mentors = JSON.parse(JSON.stringify(state.allMentors));
    mentors.sort((a, b) => {
      return b.sessionsSold - a.sessionsSold;
    });
    return mentors.slice(0, 10);
  },
  getLatestMentors(state) {
    const mentors = JSON.parse(JSON.stringify(state.allMentors));
    mentors.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    return mentors.slice(0, 10);
  },
};

const user = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default user;
