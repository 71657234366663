import { functions } from "@/firebase";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    sessions: [],
    session: {},
    unhandledCanceledSessions: [],
    handledCanceledSessions: [],
    status: {},
    waiting: false,
    canceledWaiting: false,
    handleWaiting: false,
    error: null,
  };
};

const state = getDefaultState();

const actions = {
  async getCanceledSessions({ commit }, handled = false) {
    try {
      if (handled) commit("waitCanceled");
      else commit("wait");
      const getCanceledSessions = httpsCallable(
        functions,
        "getCanceledSessions"
      );
      const res = await getCanceledSessions(handled);
      if (!res.data.success) throw res.data.code || "unknown";
      if (handled) commit("setHandledCanceledSessions", res.data.data);
      else commit("setUnhandledCanceledSessions", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async handleCanceledSession({ commit }, data) {
    try {
      commit("waitHandling");
      const handleCanceledSession = httpsCallable(
        functions,
        "handleCanceledSession"
      );
      const res = await handleCanceledSession(data);
      if (!res.data.success) throw res.data.code || "unknown";
      await commit("setCanceledSessionHandled", data);
      commit("setSuccess", "session_handled");
      return true;
    } catch (error) {
      commit("failure", error);
      return false;
    }
  },
  async getSession({ commit }, id) {
    try {
      commit("wait");
      const getSession = httpsCallable(functions, "getSession");
      const res = await getSession(id);
      if (!res.data.success) throw res.data.code || "unknown";
      commit("setSession", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  setSession(state, session) {
    state.session = session;
    state.waiting = false;
  },
  setSessions(state, sessions) {
    state.sessions = state.sessions.concat(sessions);
    state.waiting = false;
  },
  setUnhandledCanceledSessions(state, sessions) {
    state.unhandledCanceledSessions = sessions;
    state.waiting = false;
  },
  setHandledCanceledSessions(state, sessions) {
    state.handledCanceledSessions = sessions;
    state.canceledWaiting = false;
    state.waiting = false;
  },
  setCanceledSessionHandled(state, data) {
    const id = data.id,
      handled = data.handled;
    if (state.session && state.session.id && state.session.id == id)
      state.session.cancelHandled = handled;
    if (handled) {
      if (state.unhandledCanceledSessions.length) {
        const index = state.unhandledCanceledSessions.findIndex(
          (s) => s.id == id
        );
        if (index != -1 && state.handledCanceledSessions.length)
          state.handledCanceledSessions.push(
            state.unhandledCanceledSessions[index]
          );
        if (index != -1) state.unhandledCanceledSessions.splice(index, 1);
      }
    } else {
      if (state.handledCanceledSessions.length) {
        const index = state.handledCanceledSessions.findIndex(
          (s) => s.id == id
        );
        if (index != -1 && state.unhandledCanceledSessions.length)
          state.unhandledCanceledSessions.push(
            state.handledCanceledSessions[index]
          );
        if (index != -1) state.handledCanceledSessions.splice(index, 1);
      }
    }
    state.canceledWaiting = false;
    state.handleWaiting = false;
    state.waiting = false;
  },
  replaceSession(state, session) {
    const index = state.sessions.findIndex((s) => s.id == session.id);
    if (index != -1) state.sessions.splice(index, 1, session);
    state.session = session;
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.canceledWaiting = false;
    state.handleWaiting = false;
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
  waitCanceled(state) {
    state.canceledWaiting = true;
  },
  waitHandling(state) {
    state.handleWaiting = true;
  },
};

const getters = {
  getSession(state) {
    return state.session;
  },
};

const session = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default session;
