<template>
  <div
    v-if="!item.mentorOnly || (item.mentorOnly && isMentor)"
    class="focus:outline-none flex cursor-pointer items-center p-2 text-white md:hover:bg-darkblue-100 md:focus:ring md:focus:ring-darkblue-300 border-0 md:hover:bg-darkblue-400 md:h-full"
    :class="[
      item.class ? item.class : '',
      isActivePage(item)
        ? 'border-primary border-b-4 opacity-100 '
        : 'md:border-darkblue-300 opacity-75',
    ]"
  >
    <component class="h-6 w-6 md:h-4 md:w-4" :is="item.icon" />
    <span class="ml-2.5 font-semibold hidden md:block my-0">{{
      t(`navigation.${item.label}`)
    }}</span>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { currentUserIsMentor } from "../../utils/userUtils";
export default {
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const route = useRoute();
    const { t } = useI18n();

    const routeIncludesAlternatePath = (paths, currentPath) => {
      let includes = false;
      for (let path of paths) if (currentPath.includes(path)) includes = true;
      return includes;
    };

    const isActivePage = (item) =>
      route.path == item.path ||
      (route.path != item.path && route.path.includes(item.label)) ||
      (route.path != item.path &&
        item.alternatePaths &&
        item.alternatePaths.length &&
        routeIncludesAlternatePath(item.alternatePaths, route.path));

    const isMentor = ref(false);
    onMounted(async () => (isMentor.value = await currentUserIsMentor()));

    return {
      route,
      t,
      isActivePage,
      isMentor,
    };
  },
};
</script>
