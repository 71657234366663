<template>
  <div
    class="flex justify-between md:justify-normal w-full h-full md:gap-y-2 gap-3"
  >
    <navigation-item
      v-for="item in items"
      :key="item.index"
      :item="item"
      @click="navigateTo(item)"
    />
  </div>
</template>

<script>
import { markRaw, reactive } from "vue";
import { Home2, MenuBoard, Profile2User, User, Calendar2 } from "vue-iconsax";
import { useRoute, useRouter } from "vue-router";
import NavigationItem from "./NavigationItem.vue";
export default {
  components: { NavigationItem },
  setup(_, { emit }) {
    const router = useRouter();
    const route = useRoute();

    const items = reactive([
      { icon: markRaw(Home2), label: "home", path: "/" },
      {
        icon: markRaw(Profile2User),
        label: "mentors",
        path: "/mentors",
      },
      {
        icon: markRaw(MenuBoard),
        label: "categories",
        path: "/categories",
      },
      {
        icon: markRaw(Calendar2),
        label: "sessions",
        path: "/sessions",
      },
      {
        icon: markRaw(User),
        label: "profile",
        path: "/profile",
        class: "md:hidden",
      },
    ]);

    const navigateTo = (item) => {
      if (item.path == route.path) return;
      router.push(item.path);
      emit("nav-clicked");
    };

    return {
      items,
      navigateTo,
    };
  },
};
</script>
