<template>
  <div
    class="z-50 w-80 rounded-lg bg-darkblue-400 p-4 shadow-md transition-opacity duration-500"
    :class="{ 'opacity-0': opacity == 0, 'opacity-100': opacity == 1 }"
  >
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-1">
        <TickCircle
          v-if="severity == 'success'"
          class="h-7 w-7 text-green-600"
        />
        <InfoCircle
          v-else-if="severity == 'warning'"
          class="h-7 w-7 text-yellow-600"
        />
        <InfoCircle
          v-else-if="severity == 'info'"
          class="h-7 w-7 text-primary-600"
        />
        <CloseCircle v-else class="h-7 w-7 text-rose-600" />
        <h3
          :class="{
            'text-green-100': severity == 'success',
            'text-yellow-100': severity == 'warning',
            'text-primary-100': severity == 'info',
            'text-rose-100': severity == 'error',
          }"
        >
          {{ t(`toast.headings.${severity}`) }}
        </h3>
      </div>
      <button
        class="rounded-full p-1 hover:bg-darkblue-400"
        type="button"
        @click="close()"
      >
        <CloseCircle class="h-5 w-5" />
      </button>
    </div>
    <div
      class="mt-2 rounded-lg px-3 py-2"
      :class="{
        'text-green-100': severity == 'success',
        'text-yellow-100': severity == 'warning',
        'text-primary-100': severity == 'info',
        'text-rose-100': severity == 'error',
      }"
    >
      <p v-if="severity == 'success'" class="text-white">
        {{
          te(`toast.successes.${content}`)
            ? t(`toast.successes.${content}`)
            : t("toast.successes.default")
        }}
      </p>
      <p v-else-if="severity == 'error'" class="text-white">
        {{
          te(`toast.errors.${content}`)
            ? t(`toast.errors.${content}`)
            : t("toast.errors.default")
        }}
      </p>
    </div>
  </div>
</template>

<script>
import { TickCircle, InfoCircle, CloseCircle } from "vue-iconsax";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
export default {
  components: {
    TickCircle,
    InfoCircle,
    CloseCircle,
  },
  props: {
    content: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: Number,
    },
    life: {
      default: 0,
      required: false,
      type: Number,
    },
    severity: {
      validator(value) {
        return ["error", "info", "success", "warning"].includes(value);
      },
    },
  },
  emits: ["destroy"],
  setup(props, { emit }) {
    const { t, te } = useI18n();

    const opacity = ref(0);

    const close = () => {
      opacity.value = 0;
      setTimeout(() => emit("destroy", props.id), 500);
    };

    const timer = (life) => {
      setTimeout(close, life);
    };

    onMounted(() => {
      setTimeout(() => {
        opacity.value = 1;
      }, 0);
      if (props.life) {
        timer(props.life);
      }
    });

    return {
      close,
      opacity,
      t,
      te,
    };
  },
};
</script>
