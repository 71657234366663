<template>
  <div
    class="fixed bottom-0 left-0 z-50 w-full flex items-center justify-between bg-darkblue py-3 px-5 md:hidden menu-footer-border"
  >
    <navigation-items @nav-clicked="toggleNavigation" />
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import NavigationItems from "./NavigationItems.vue";
import NavigationProfile from "./NavigationProfile.vue";
export default {
  components: {
    NavigationItems,
    NavigationProfile,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const showNavigation = ref(false);
    const toggleNavigation = () =>
      (showNavigation.value = !showNavigation.value);

    return {
      showNavigation,
      signOut: () => store.dispatch("authentication/signOut"),
      t,
      toggleNavigation,
    };
  },
};
</script>
