import { db, functions } from "@/firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";

const getDefaultState = () => {
  return {
    categories: [],
    category: {},
    status: {},
    waiting: false,
    error: null,
  };
};

const state = getDefaultState();

const actions = {
  async getCategories({ commit }) {
    try {
      commit("wait");
      const q = query(collection(db, "categories"));
      const querySnapshot = await getDocs(q);
      const categories = querySnapshot.docs.map((doc) => {
        const category = doc.data();
        category.id = doc.id;
        return category;
      });
      commit("setCategories", categories);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getCategory({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "categories", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const category = docSnap.data();
        category.id = docSnap.id;
        commit("setCategory", category);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async createCategory({ commit }, data) {
    try {
      commit("wait");
      const docRef = await addDoc(collection(db, "categories"), data);
      const category = data;
      category.id = docRef.id;

      commit("setSuccess", "category_created");
      commit("addCategory", category);
    } catch (error) {
      console.log(error);
      commit("failure", error);
    }
  },
  async updateCategory({ commit }, { data, categoryId }) {
    try {
      commit("wait");
      const docRef = doc(db, "categories", categoryId);
      await updateDoc(docRef, data);
      const category = data;
      category.id = categoryId;
      commit("setSuccess", "category_updated");
      commit("replaceCategory", category);
    } catch (error) {
      console.log(error);
      commit("failure", error);
    }
  },
  async deleteCategory({ commit }, categoryId) {
    try {
      commit("wait");
      const docRef = doc(db, "categories", categoryId);
      await deleteDoc(docRef);
      commit("setSuccess", "category_deleted");
      commit("removeCategory", categoryId);
    } catch (error) {
      console.log(error);
      commit("failure", error);
    }
  },
  async searchInCategoriesAndTags({ commit }, data) {
    try {
      commit("wait");
      const docRef = doc(db, "categories", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const category = docSnap.data();
        category.id = docSnap.id;
        commit("setCategory", category);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  setUpcomingCategories(state, upcomingCategories) {
    state.upcomingCategories = upcomingCategories;
    state.waiting = false;
  },
  setCategory(state, category) {
    state.category = category;
    state.waiting = false;
  },
  setCategories(state, categories) {
    state.categories = categories;
    state.waiting = false;
  },
  addCategory(state, category) {
    state.categories.push(category);
    state.waiting = false;
  },
  replaceCategory(state, category) {
    const index = state.categories.findIndex((b) => b.id == category.id);
    if (index != -1) state.categories.splice(index, 1, category);
    state.category = category;
    state.waiting = false;
  },
  removeCategory(state, categoryId) {
    const index = state.categories.findIndex((b) => b.id == categoryId);
    if (index != -1) state.categories.splice(index, 1);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getCategory(state) {
    return state.category;
  },
};

const category = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default category;
